@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800;900&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
}

ul>li, ol>li {
  margin-bottom: 14px;
}
.b-bottom{
  border-bottom: 1.5px solid #eee;
}
.main-container {
  overflow: hidden;
}

.left-side {
  background-color: #FFFFFF;
  padding: 10px 20px;
  /* z-index: -1; */
  /* background: rgb(255, 255, 255); */
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(133, 210, 73, 1) 100%); */
}

.right-side {
  background-color: #F7F6F9;
  padding: 10px 20px;
  /* z-index: -1; */

}

.weather-icon {
  font-size: 2pc;
  min-height: 220px;
  display: block;

}


.input-box {
  /* padding: 10px 22px; */
  padding: 15px 10px 15px 15px;
  border-radius: 10px;

}

.search-btn {
  /* padding: 10px 15px;
  cursor: pointer; */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0 10px 10px 0 !important;

}


.form-control:focus {
  box-shadow: none;
  border-color: none;
}

.card {
  margin: 25px 20px;
  border-radius: 10px;
  border: none;
}

.card-title {
  font-weight: 500;
  color: #cfcfcf;
  display: block;
}

.card-title-bottom {
  font-weight: 500;

  display: block;
}

.heading {
  font-weight: 700;
}

.text-big {
  font-size: 43px;
  margin: 10px 5px;
  display: inline-block;
  position: relative;
  font-weight: 600;
}

.text-sm-text,
.text-temp {
  font-size: 18px;
  position: relative;
  font-weight: 600;
}

.text-temp-up {
  font-size: 14px;
  position: absolute;
}

.text-sm {
  font-size: 18px;
}

hr {
  color: #F7F6F9;
}

.text-sm-up-left {
  font-size: 25px;
  position: absolute;
  top: 20px;
}

.text-big-left {
  font-size: 80px;
  font-weight: 600;
  margin: 10px 5px;
  display: inline-block;
  position: relative;
}

.text-sm-up {
  font-size: 20px;
  position: absolute;
  top: 10px;
}


.w-state {
  display: block;
  font-weight: 600;
  font-size: 25px;
}

.w-country {

  color: #cfcfcf;

}

.day {
  display: block;
  font-weight: 500;
}

.time {
  color: #cfcfcf;
}

.footer {
  border-top: 1px solid #eee;
  font-size: 14px;
}

.nav-logo {
  border-bottom: 1px solid #d5d5d5;
}

.nav-logo img {
  width: 160px;
  padding: 10px 0;
}

.text-sm-logo {
  font-size: 28px;
}